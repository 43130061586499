import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import {
    PageHeader,
    Table,
    Button,
    DatePicker,
    Empty,
    message,
    Modal,
    Select,
    Space
} from "antd";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DOMPurify from "dompurify";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Notifications = ({ isAdmin, permissions, pageWidth }) => {
    const [dates, setDates] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);

    // For the message modal
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState("");
    const [isMessageHTML, setIsMessageHTML] = useState(false);

    // ---- NEW STATE FOR CLIENT DROPDOWN ----
    const [clients, setClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);

    // Fetch the clients on component mount (adjust if you already have them in Redux, etc.)
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const res = await axios.get("/api/clients");
                // Example: res.data = [{ _id: "123", name: "Client A" }, { _id: "456", name: "Client B" }]
                setClients(res.data || []);
            } catch (error) {
                console.error("Error fetching clients:", error);
                message.error("Failed to load clients.");
            }
        };

        fetchClients();
    }, []);

    if (!isAdmin && !permissions?.write) {
        return <div>Access Denied</div>;
    }

    const handleDateChange = (value) => {
        if (value) {
            const [start, end] = value;
            const dateFrom = start.format("YYYY-MM-DD");
            const dateTo = end.format("YYYY-MM-DD");
            setDates({ dateFrom, dateTo });
        } else {
            setDates(null);
        }
    };

    const fetchNotifications = async () => {
        // Ensure we have at least one client selected
        if (!selectedClients || selectedClients.length === 0) {
            message.warning("Please select at least one client.");
            return;
        }

        try {
            setLoading(true);

            let url = "/api/notifications";
            // Add client IDs as a comma-separated list
            url += `?clients=${selectedClients.join(",")}`;

            if (dates) {
                url += `&fromDate=${dates.dateFrom}&toDate=${dates.dateTo}`;
            }

            const res = await axios.get(url);

            if (Array.isArray(res.data)) {
                setNotifications(res.data);
                setFilteredNotifications(res.data);
                if (res.data.length === 0) {
                    message.info("No notifications found for the selected criteria.");
                }
            } else {
                console.error("Unexpected data format received from server:", res.data);
                setNotifications([]);
                setFilteredNotifications([]);
                message.error("Unexpected data format received from server.");
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
            message.error("Failed to fetch notifications.");
            setNotifications([]);
            setFilteredNotifications([]);
        } finally {
            setLoading(false);
        }
    };

    const extractPlainText = (htmlContent) => {
        if (typeof htmlContent !== "string") return "";

        const sanitizedHTML = DOMPurify.sanitize(htmlContent, {
            ALLOWED_TAGS: ["b", "i", "em", "strong", "a", "p", "br", "ul", "ol", "li", "div", "span"],
            ALLOWED_ATTR: ["href", "target", "style"],
        });

        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = sanitizedHTML;

        tempDiv.querySelectorAll("br").forEach((br) => br.replaceWith(" "));
        let plainText = tempDiv.textContent || tempDiv.innerText || "";

        plainText = plainText
            .replace(/[​‌‍﻿]/g, "")
            .replace(/(\r\n|\n|\r)/g, " ")
            .replace(/\s+/g, " ")
            .trim();

        return plainText;
    };

    const handleExportExcel = () => {
        if (filteredNotifications.length === 0) {
            message.warning("No data available to export.");
            return;
        }

        try {
            const dataToExport = filteredNotifications.map((notification) => ({
                client_name: notification.client_name || "-",
                site_name: notification.site_name || "-",
                user_email: notification.user_email || "-",
                user_cell: notification.user_cell || "-",
                communication_method: notification.communication_method || "-",
                status: notification.status || "-",
                date_requested: notification.date_requested
                    ? new Date(notification.date_requested).toLocaleString()
                    : "-",
                message_content: notification.message_content
                    ? extractPlainText(notification.message_content)
                    : "-",
            }));

            const worksheet = XLSX.utils.json_to_sheet(dataToExport);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Notifications");
            XLSX.writeFile(workbook, "Notifications.xlsx");
            message.success("Notifications exported to Excel successfully.");
        } catch (error) {
            console.error("Error exporting to Excel:", error);
            message.error("Failed to export notifications to Excel.");
        }
    };

    const handleCountReport = async () => {
        // If there's no data or no filters
        if (!dates || !dates.dateFrom || !dates.dateTo || !selectedClients || selectedClients.length === 0) {
            message.warning("Please select a date range and at least one client.");
            return;
        }

        setLoading(true);

        try {
            // Build the query string
            // e.g. /api/notifications/report?fromDate=2023-01-01&toDate=2023-01-31&clients=123,456
            const url = `/api/notifications/report?fromDate=${dates.dateFrom}&toDate=${dates.dateTo}&clients=${selectedClients.join(",")}`;

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Failed to generate PDFs on the server.");
            }

            // { results: [ { client: 'Client A', base64: '...' }, ... ] }
            const data = await response.json();
            if (!data.results || !Array.isArray(data.results) || data.results.length === 0) {
                message.warning("No data or empty PDF results returned.");
                return;
            }

            // For each client PDF, convert base64 to blob and trigger download
            data.results.forEach((pdfObj) => {
                const { client, base64 } = pdfObj;
                if (!base64) return;

                const pdfBlob = b64ToBlob(base64, "application/pdf");
                const blobURL = URL.createObjectURL(pdfBlob);

                const link = document.createElement("a");
                link.href = blobURL;
                const fileClientName = client.replace(/\s+/g, "_");
                link.download = `${fileClientName}_Notification_Count_Report.pdf`;
                link.click();
            });

            message.success("All PDF reports generated and downloaded.");
        } catch (error) {
            console.error(error);
            message.error("Something went wrong generating the PDFs.");
        } finally {
            setLoading(false);
        }
    };

// Same helper function to convert base64 -> Blob
    function b64ToBlob(b64Data, contentType = "", sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        return new Blob(byteArrays, { type: contentType });
    }

    // Helper function to convert base64 -> Blob
    function b64ToBlob(b64Data, contentType = "", sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            byteArrays.push(new Uint8Array(byteNumbers));
        }
        return new Blob(byteArrays, { type: contentType });
    }

    const handleExportPDF = () => {
        if (filteredNotifications.length === 0) {
            message.warning("No data available to export.");
            return;
        }

        try {
            const doc = new jsPDF();
            doc.text("Notifications Report", 14, 16);
            const tableColumn = [
                "Client Name",
                "Site Name",
                "User Email",
                "User Cell",
                "Method",
                "Status",
                "Date Requested",
            ];
            const tableRows = [];

            filteredNotifications.forEach((notification) => {
                const notificationData = [
                    notification.client_name || "-",
                    notification.site_name || "-",
                    notification.user_email || "-",
                    notification.user_cell || "-",
                    notification.communication_method || "-",
                    notification.status || "-",
                    notification.date_requested
                        ? new Date(notification.date_requested).toLocaleString()
                        : "-",
                ];
                tableRows.push(notificationData);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 20,
            });
            doc.save("Notifications.pdf");
            message.success("Notifications exported to PDF successfully.");
        } catch (error) {
            console.error("Error exporting to PDF:", error);
            message.error("Failed to export notifications to PDF.");
        }
    };

    const handleRowClick = (record) => {
        const { message_content } = record;
        if (!message_content) {
            message.warning("No message content available for this notification.");
            return;
        }

        const isHTML = /<\/?[a-z][\s\S]*>/i.test(message_content);

        setSelectedMessage(message_content);
        setIsMessageHTML(isHTML);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedMessage("");
        setIsMessageHTML(false);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        let filteredData = [...notifications];

        Object.keys(filters).forEach((key) => {
            if (filters[key]) {
                filteredData = filteredData.filter((item) =>
                    filters[key].includes(item[key])
                );
            }
        });

        setFilteredNotifications(filteredData);
    };

    const columns = [
        {
            title: "Client Name",
            dataIndex: "client_name",
            key: "client_name",
            filters: Array.from(
                new Set(
                    notifications.map((n) => ({
                        text: n.client_name,
                        value: n.client_name,
                    }))
                )
            ).filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            ),
            onFilter: (value, record) => record.client_name === value,
        },
        {
            title: "Site Name",
            dataIndex: "site_name",
            key: "site_name",
            filters: Array.from(
                new Set(
                    notifications.map((n) => ({
                        text: n.site_name,
                        value: n.site_name,
                    }))
                )
            ).filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            ),
            onFilter: (value, record) => record.site_name === value,
        },
        {
            title: "User Email",
            dataIndex: "user_email",
            key: "user_email",
            filters: Array.from(
                new Set(
                    notifications.map((n) => ({
                        text: n.user_email,
                        value: n.user_email,
                    }))
                )
            ).filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            ),
            onFilter: (value, record) => record.user_email === value,
        },
        {
            title: "User Cell",
            dataIndex: "user_cell",
            key: "user_cell",
            filters: Array.from(
                new Set(
                    notifications.map((n) => ({
                        text: n.user_cell,
                        value: n.user_cell,
                    }))
                )
            ).filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            ),
            onFilter: (value, record) => record.user_cell === value,
        },
        {
            title: "Method",
            dataIndex: "communication_method",
            key: "communication_method",
            filters: Array.from(
                new Set(
                    notifications.map((n) => ({
                        text: n.communication_method,
                        value: n.communication_method,
                    }))
                )
            ).filter(
                (v, i, a) => a.findIndex((t) => t.value === v.value) === i
            ),
            onFilter: (value, record) => record.communication_method === value,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: [
                { text: "Success", value: "success" },
                { text: "Failed", value: "failed" },
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: "Date Requested",
            dataIndex: "date_requested",
            key: "date_requested",
            sorter: (a, b) =>
                new Date(a.date_requested) - new Date(b.date_requested),
        },
    ];

    return (
        <Fragment>
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Notifications Log"
                extra={[
                    <Button
                        key="Refresh"
                        icon={<SyncOutlined />}
                        onClick={fetchNotifications}
                        loading={loading}
                        // Disable the button if no date range or no clients selected
                        disabled={
                            loading ||
                            !dates ||
                            !selectedClients ||
                            selectedClients.length === 0
                        }
                    >
                        Fetch Logs
                    </Button>,
                    <Button
                        key="Excel"
                        icon={<DownloadOutlined />}
                        onClick={handleExportExcel}
                        disabled={filteredNotifications.length === 0}
                    >
                        Export Excel
                    </Button>,
                    <Button
                        key="PDF"
                        icon={<DownloadOutlined />}
                        onClick={handleExportPDF}
                        disabled={filteredNotifications.length === 0}
                    >
                        Export PDF
                    </Button>,
                    <Button
                        key="CountReport"
                        icon={<DownloadOutlined />}
                        onClick={handleCountReport}
                        disabled={filteredNotifications.length === 0}
                    >
                        Count Report
                    </Button>,
                ]}
            >
                <Space direction="horizontal">
                    <RangePicker onChange={handleDateChange} disabled={loading} />
                    <Select
                        mode="multiple"
                        style={{ minWidth: 200 }}
                        placeholder="Select client(s)"
                        value={selectedClients}
                        onChange={setSelectedClients}
                        disabled={loading}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                    >
                        {clients.map((client) => (
                            <Option key={client._id} value={client._id}>
                                {client.name}
                            </Option>
                        ))}
                    </Select>

                </Space>
            </PageHeader>

            {notifications.length > 0 ? (
                <Table
                    columns={columns}
                    dataSource={notifications}
                    rowKey="_id"
                    loading={loading}
                    onChange={handleTableChange}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                        onKeyPress: (e) => {
                            if (e.key === "Enter") {
                                handleRowClick(record);
                            }
                        },
                        tabIndex: 0,
                        role: "button",
                    })}
                    pagination={{ pageSize: 10, showSizeChanger: true }}
                    style={{ margin: "16px" }}
                />
            ) : (
                <Empty description="No data available" />
            )}

            <Modal
                title="Message Content"
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={[
                    <Button key="close" onClick={handleModalClose}>
                        Close
                    </Button>,
                ]}
                width={800}
            >
                {isMessageHTML ? (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(selectedMessage, {
                                ALLOWED_TAGS: [
                                    "b",
                                    "i",
                                    "em",
                                    "strong",
                                    "a",
                                    "p",
                                    "br",
                                    "ul",
                                    "ol",
                                    "li",
                                    "div",
                                    "span",
                                ],
                                ALLOWED_ATTR: ["href", "target", "style"],
                            }),
                        }}
                    />
                ) : (
                    <p>{selectedMessage}</p>
                )}
            </Modal>
        </Fragment>
    );
};

Notifications.propTypes = {
    permissions: PropTypes.object,
    isAdmin: PropTypes.bool,
    pageWidth: PropTypes.number,
};

const mapStateToProps = (state) => ({
    isAdmin: state.auth.isAdmin,
    pageWidth: state.misc?.width,
    permissions: state.auth?.permissions?.filter(
        (item) => item.name === "Finance Module Access"
    )[0],
});

export default connect(mapStateToProps)(Notifications);
